const $ = require('jquery');

window.addEventListener("load",function(){
    // une fois à la connexion et à l'ouverture du CTI
    let request_permission = $('#value_request_permission').val();
    if (typeof request_permission != 'undefined' && request_permission === '1') {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                // force save info
                logPush('info', 'notification allowed');
                regWorker().catch(err => {
                    console.error(err);
                    logPush('error', 'Registration worker error ' + String(err));
                });
            } else {
                $('.js-flash-message-error .text').html(Translator.trans('crm.push_notification.message') + '<a href="#" class="button-default" id="enableNotifications">' + Translator.trans('crm.push_notification.authorize') + '</a>');
                $('.js-flash-message-error').removeClass('--hidden');
                $('#enableNotifications').on('click', function(event) {
                    event.preventDefault();
                    requestNotificationPermission();
                })
            }
        }
    }
});

async function regWorker () {

    // Register service worker
    let asset_version = $('#value_asset_version').val();
    let registration = await navigator.serviceWorker.register("/worker.js?v=" + asset_version, { scope: "/" });

    // Subscribe to push server
    navigator.serviceWorker.ready
        .then(reg => {
            reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: $('#value_vapid_public_key').val()
            }).then(
                subscription => {

                    // Save info in database
                    const key = subscription.getKey('p256dh');
                    const token = subscription.getKey('auth');
                    const endpoint = subscription.endpoint;
                    $.ajax({
                        url: Routing.generate('pushsubscriber_save'),
                        type: 'POST',
                        data: {
                            'endpoint': endpoint,
                            'key': btoa(String.fromCharCode.apply(null, new Uint8Array(key))),
                            'token': btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
                        },
                    }).done(function (response) {
                        logPush('info', response.data);
                    });
                },

                // Error
                err => {
                    console.error(err);
                    logPush('error', 'Error subscription ' + err.message);
                }
            );
        });
}


export function logPush(type, message, duration, url) {
    $.ajax({
        url: Routing.generate('push_log'),
        type: 'POST',
        data: {
            'type': type,
            'message': message,
            'duration': duration,
            'url': url
        },
    })
}

window.requestNotificationPermission = function() {
    // Vérifie que le navigateur prend en charge les notifications
    if ("Notification" in window) {
        // Demande l'autorisation à l'utilisateur
        Notification.requestPermission(function (permission) {
            // Si l'autorisation est accordée, on peut envoyer une notification
            if (permission === "granted") {
                $('.js-flash-message-error').addClass('--hidden');
                $('.enable-notifications').addClass('--hidden');
                logPush('info', 'notification allowed');
                regWorker().catch(err => {
                    console.error(err);
                    logPush('error', String(err));
                });
            } else if (permission === "denied") {
                // Si l'autorisation est refusée, on peut afficher un message d'erreur
                console.log("Notification refusée");
                logPush('error', 'notification not allowed');
            }
        });

    } else {
        // Si le navigateur ne prend pas en charge les notifications
        logPush('error', 'Browser does not suppport notification');
    }
}