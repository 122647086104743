import {logPush} from "./push/permission-global";
import $ from "jquery";

const nonceSupported = function() {
    // Vérifie si le navigateur supporte nonce
    return (function() {
        let script = document.createElement('script');
        script.nonce = 'test';
        return script.nonce === 'test';
    })();
}

if (!nonceSupported()) {
    $.ajax({
        url: Routing.generate('nonce_log'),
        type: 'POST'
    })
}