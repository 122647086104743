document.addEventListener("DOMContentLoaded", function() {
    let toggleButtons = document.querySelectorAll(".toggle-password");

    toggleButtons.forEach(function(button) {
        button.addEventListener("click", function() {
            let passwordField = this.parentElement;
            let passwordInput = passwordField.querySelector("input");
            if (passwordInput.type === "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        });
    });
});